<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="地区" prop="regionCodes">
        <!--省市三级联动-->
        <el-cascader
          placeholder="请选择"
          v-model="ruleForm.regionCodes"
          :options="options"
          ref="myCascader"
          @change="getAreaCode"
          :props="props"
          id="myCascader"
          style="width: 90%">
        </el-cascader>
      </el-form-item>
      <el-form-item label="社区">
        <el-select v-model="ruleForm.communityIds" multiple  placeholder="请选择" style="width:90%">
          <el-option
            v-for="item in communityList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit('ruleForm')">保存</el-button>
        <el-button @click="colsed">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
   import api  from '@/api/index'
   import { chinaArea } from "@/utils/china-area-data";
    export default {
      props:{
        userId:{
          type:Number,
          require:false
         }
       },
        name: "user-power",
        data() {
            return {
              ruleForm:{
                communityIds:[],
                regionCodes:[],
              },
              props: { multiple: true ,emitPath:false},
              options:chinaArea,
              rules:{
                regionCodes:[{ required: true, message: '社区不能为空', trigger: 'blur' }]
              },
              communityList: [],
              dataArea:[],
              operate:'add'
            }
        },
      created(){
        this.fetchUserPower()
      },
      methods:{
        //根据UserID回显权限
        async fetchUserPower(){
          try {
            let res =await api.getUserPower(this.userId)
            if(res.code==200 && res.data.dataArea!=''){
              let arr =[]
              for(var i=0;i<res.data.dataArea.length;i++){
                this.operate='edit'
                if(res.data.dataArea[i]!=null){
                  arr.push(res.data.dataArea[i].regionCode)
                }
              }
              this.ruleForm.regionCodes=arr
              var newArr = res.data.comId.filter((item)=>{
                return Number(item)!=0
              })
              this.ruleForm.communityIds=newArr
              this.getAreaCode(arr)
            }
          }catch (e) {

          }
        },
        getAreaCode(val){
          var _this =this
          let Divs =document.getElementsByClassName('el-cascader__tags')
          setTimeout(()=>{
            let spans = Divs[0].children
            let newArr =[]
            for (var i=0;i<spans.length;i++){
              newArr.push({
                regionCode:val[i],
                area:spans[i].innerText
              })
            }
            this.dataArea=newArr
            console.log(newArr)
            this.fetchCommuintyList({regCode:val})
          },200)

        },
        async fetchCommuintyList(data){
          try {
            let res= await api.getComityList(data)
           if(res.code==200 && res.rows.length>0){
             this.communityList=res.rows
           }else {
             this.communityList=[]
           }
          }catch (e) {
            this.communityList=[]
          }
        },
        submit(formName){
          this.$refs[formName].validate((valid) => {
            if (valid) {
              if(this.operate=='add'){
                this.addPower({
                  "comId": this.ruleForm.communityIds,
                  "dataArea":this.dataArea,
                  "userId": this.userId
                })
              }else {
                this.updatePower({
                  "comId": this.ruleForm.communityIds,
                  "dataArea":this.dataArea,
                  "userId": this.userId
                })
              }
            }
          })
        },
        //新增权限
       async addPower(data){
          try {
            let res= await api.userPowerAdd(data)
            if(res.code==200){
              this.$message.success('授权成功！')
              this.$emit('closed')
            }
          }catch (e) {
            this.$message.warning('授权失败！')
          }
        },
        //修改权限
       async updatePower(data){
          console.log(data)
         try {
           let res= await api.userPowerUpdate(data)
           if(res.code==200){
             this.$message.success('更新授权成功！')
             this.$emit('closed')
           }
         }catch (e) {
           this.$message.warning('更新授权失败！')
         }
        },
        colsed(){
          this.$emit('closed')
        }
      }
    }
</script>

<style scoped>

</style>
