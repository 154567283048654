<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="姓名" prop="nickName">
        <el-input v-model="ruleForm.nickName"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phonenumber">
        <el-input v-model="ruleForm.phonenumber"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="userName">
        <el-input v-model="ruleForm.userName"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="ruleForm.password" show-password placeholder="请填写6到12位密码"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <el-select v-model="ruleForm.roleId" placeholder="请选择" style="width: 100%;">
          <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId" />
        </el-select>
      </el-form-item>
      <el-form-item label="地区" prop="regionCodes" v-if="operateBox.operate == 'add'">
        <!--省市三级联动-->
        <el-cascader placeholder="请选择" v-model="ruleForm.regionCodes" :options="options" ref="myCascader"
          @change="getAreaCode" :props="props" id="myCascader" style="width: 100%;">
        </el-cascader>
      </el-form-item>
      <el-form-item label="社区" prop="communityIds" v-if="operateBox.operate == 'add'">
        <el-select v-model="ruleForm.communityIds" multiple placeholder="请选择" style="width: 100%;">
          <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { chinaArea } from "@/utils/china-area-data";
import api from '@/api/index'
import debounce from 'lodash/debounce';
export default {
  name: 'edit-from',
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      props: { multiple: true, emitPath: false },
      communityList: [],
      dataArea: [],
      options: chinaArea,
      ruleForm: {
        userName: '',
        roleId: '',
        password: '',
        userId: '',
        nickName: '',
        phonenumber: '',
        regionCodes: [],
        communityIds: []
      },
      rules: {
        nickName: [
          { required: true, message: '请输入用昵称', trigger: 'blur' },
        ],
        phonenumber: [
          { required: true, message: '请输入用户手机号', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        roleId: [
          { required: true, message: '请选择用户角色', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请选择输入用户密码', trigger: 'blur' },
        ],
        communityIds: [
          { required: true, message: '请选择社区', trigger: 'blur' },
        ],
        regionCodes: [
          { required: true, message: '请选择地区', trigger: 'blur' },
        ]
      },
      roleList: []
    }
  },
  created() {
    if (this.operateBox.row != null) {
      this.ruleForm = {
        userName: this.operateBox.row.userName,
        roleId: this.operateBox.row.roleId,
        password: this.operateBox.row.password,
        userId: this.operateBox.row.userId,
        phonenumber: this.operateBox.row.phonenumber,
        nickName: this.operateBox.row.nickName
      }
    }
    this.getRoleList()
  },
  methods: {
    getAreaCode(val) {
      var _this = this
      let Divs = document.getElementsByClassName('el-cascader__tags')
      setTimeout(() => {
        let spans = Divs[0].children
        let newArr = []
        for (var i = 0; i < spans.length; i++) {
          newArr.push({
            regionCode: val[i],
            area: spans[i].innerText
          })
        }
        this.dataArea = newArr
        console.log(newArr)
        this.fetchCommuintyList({ regCode: val })
      }, 200)

    },
    async fetchCommuintyList(data) {
      try {
        let res = await api.getComityList(data)
        if (res.code == 200 && res.rows.length > 0) {
          this.communityList = res.rows
        } else {
          this.communityList = []
        }
      } catch (e) {
        this.communityList = []
      }
    },
    //获取角色列表
    async getRoleList() {
      let p = {
        pageNum: 1,
        pageSize: 99999
      }
      let res = await api.getRoleList(p);
      if (res.code == 200) {
        this.roleList = res.rows;
      } else {
        this.roleList = []
      }
    },

    submitForm: debounce(function (formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.operateBox.operate == 'add') {
            this.addUser({
              userName: this.ruleForm.userName,
              roleId: Number(this.ruleForm.roleId),
              password: this.ruleForm.password,
              nickName: this.ruleForm.nickName,
              phonenumber: this.ruleForm.phonenumber
            })
          } else {
            this.updateUser({
              userId: Number(this.ruleForm.userId),
              userName: this.ruleForm.userName,
              roleId: Number(this.ruleForm.roleId),
              password: this.ruleForm.password,
              nickName: this.ruleForm.nickName,
              phonenumber: this.ruleForm.phonenumber
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 500),
    async addUser(p) {
      let res = await api.addSysUser(p)
      console.log(res);
      if (res.code == 200) {
        await this.addPower({
          "comId": this.ruleForm.communityIds,
          "dataArea": this.dataArea,
          "userId": res.data
        })
        this.$message.success('新增成功')
        this.$emit('closed')
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateUser(p) {
      let res = await api.updateSysUser(p)
      if (res.code == 200) {
        this.$emit('closed')
        this.$message.success('修改成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    //新增权限
    async addPower(data) {
      try {
        let res = await api.userPowerAdd(data)
        if (res.code == 200) {
          this.$message.success('授权成功！')
          this.$emit('closed')
        }
      } catch (e) {
        this.$message.warning('授权失败！')
      }
    },
  }
}
</script>

<style scoped></style>
