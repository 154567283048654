<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>后台账号</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-plus" @click="addTest"></el-button>
        <el-button type="primary" icon="el-icon-delete" @click="deleteTest"></el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="userId" label="ID" width="100" />
        <el-table-column fixed prop="userName" label="账号" width="200" />
        <el-table-column fixed prop="nickName" label="姓名" width="200" />
        <el-table-column fixed prop="roleName" label="角色" width="200" />
        <el-table-column fixed prop="loginIp" label="最后登录IP" width="200" />
        <el-table-column fixed prop="loginDate" label="最后登录时间" width="200" />
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button type="primary" icon="el-icon-edit" circle @click="editTest(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="权限设置" placement="top">
              <el-button type="warning" icon="el-icon-s-opportunity" circle @click="setPower(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!--新增编辑小组-->
    <el-dialog :visible.sync="editDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" :key="timer" @closed="closed"></edit-from>
    </el-dialog>

    <el-drawer :visible.sync="powerDrawer" title="用户权限设置">
      <user-power :userId="userId" @closed="closed" :key="timer"></user-power>
    </el-drawer>
  </div>
</template>

<script>
import EditFrom from './components/edit-from'
import api from '@/api/index'
import UserPower from "./components/user-power";


export default {
  components: { UserPower, EditFrom },
  data() {
    return {
      value1: true,
      tableData: [],
      editDialog: false,
      powerDrawer: false,
      operateBox: {
        title: '添加后台账号',
        operate: 'add',
        id: null
      },
      pageBox: {
        pageSize: 10,
        total: 0,
        pageNum: 1
      },
      searchForm: {
        "userName": ""
      },
      timer: null,
      userId: null
    }
  },
  created() {
    this.getUserList()
  },
  methods: {

    async getUserList() {
      try {
        let res = await api.getSystemUser(this.searchForm, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getUserList()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getUserList()
    },
    // 新增样例
    addTest() {
      this.timer = new Date().getTime()
      this.operateBox = {
        title: '添加后台账号',
        operate: 'add',
        row: null
      }
      this.editDialog = true
    },
    editTest(row) {
      this.timer = new Date().getTime()
      this.operateBox = {
        title: '编辑后台账号',
        operate: 'edit',
        row: row
      }
      this.editDialog = true
    },
    //删除弹框
    deleteTest() {
      this.$alert('请选择删除项', '信息', {
        confirmButtonText: '确定'
      })
    },
    closed() {
      this.editDialog = false
      this.powerDrawer = false
      this.getUserList()
    },
    setPower(row) {
      this.timer = new Date().getTime()
      this.userId = row.userId
      this.powerDrawer = true
    },
  }
}
</script>

<style scoped lang="less">
/*页面特殊样式*/
.one_tag,
.two_tag,
.three_tag {
  padding: 5px 12px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  color: #fff;
}

.one_tag {
  background: #c60c00;
  background: -webkit-linear-gradient(to bottom, #eb1610, #c66334);
  background: linear-gradient(to bottom, #eb1600, #c65f00);
}

.two_tag {
  background: #eb6601;
  background: -webkit-linear-gradient(to bottom, #eb6601, #c6aa07);
  background: linear-gradient(to bottom, #eb6601, #c6aa07)
}

.three_tag {
  background: #ebd007;
  background: -webkit-linear-gradient(to bottom, #ebd007, #bec606);
  background: linear-gradient(to bottom, #ebd007, #bec606)
}
</style>
